import TreeNode from "../../../structures/TreeNode";
import random from "../../../utils/random";

const pos1 = new TreeNode(0, false, -1750, 1630, "", 0);

const pos2 = new TreeNode(0, false, -2320, 1960, "", 0);

const pos3 = new TreeNode(0, false, -3120, 2415, "", 0);

const pos4 = new TreeNode(0, false, -4190, 2990, "", 0);

const pos5 = new TreeNode(0, false, -4950, 3045, "", 0);

const pos6 = new TreeNode(0, false, -5500, 3110, "", 0);

const pos7 = new TreeNode(0, false, -6348, 3210, "", 0);

const node1 = new TreeNode(
  0,
  false,
  -1750,
  1630,
  "Mi hijo está por cumplir 18 años, y además está por entrar a la universidad, ¿habrá algo que pueda hacer para seguir recibiendo su pensión?",
  0
);

node1.quotes = [
  "“Gracias a Dios mi mamá sabía que podía hacer eso, si no me iban a quitar la pensión de mi hijas y no sé qué hubiera hecho”",
  "“Nunca me comentaron que podía hacer si mis hijos cumplían mas de 18 años, pensé que iba a perder ese apoyo”",
];

const node2_1 = new TreeNode(
  1,
  false,
  -2320,
  1960,
  "Hola estás llamando a Profuturo, ¿en qué podemos ayudarte?",
  0
);

node2_1.dialogAnchor = { x: 4.8, y: 3.2 };

node2_1.quotes = [
  "“Que bueno que sienten empatía por nosotros, sin este apoyo nunca hubiera podido pagarle la escuela a mis hijos”",
];

const node2_2 = new TreeNode(
  0,
  false,
  -2320,
  1960,
  "Hola, actualmente recibo la pensión por viudez y orfandad, mi hijo está por cumplir los 18 años, y quería saber si hay algo que pueda hacer para seguir recibiendo su pensión.",
  0
);

const node2_3 = new TreeNode(
  1,
  false,
  -2320,
  1960,
  "Claro, la única forma de seguir recibiendo la pensión es comprobando que tu hijo sigue estudiando.",
  0
);

node2_3.dialogAnchor = { x: 4.8, y: 3.2 };

const node2_4 = new TreeNode(
  0,
  false,
  -2320,
  1960,
  "Está por entrar a la Universidad, ¿necesitarían que la escuela hiciera una carta y que se las mande?",
  0
);

const node2_5 = new TreeNode(
  1,
  false,
  -2320,
  1960,
  "La forma de comprobar que tu hijo sigue estudiad es con una constacia de estudios, esta la deberás de subir al portal de pensiones.",
  0
);

node2_5.dialogAnchor = { x: 4.8, y: 3.2 };

const node2_6 = new TreeNode(
  0,
  false,
  -2320,
  1960,
  "¿Dónde está el portal de pensionados? ¿tengo que registrarme?",
  0
);

const node2_7 = new TreeNode(
  1,
  false,
  -2320,
  1960,
  'Así es. Para entrar al portal de pensionados debes de ingresar al portal de Profuturo: profuturo.mx, y en el menú superior del lado derecho de la pantalla vendrá un botón que dice "Registro al portal" tienes que dar clic ahí y después seleccionar el registro al portal de pensiones.',
  0
);

node2_7.dialogAnchor = { x: 4.8, y: 3.2 };

const node2_8 = new TreeNode(0, false, -2320, 1960, "Muchas gracias.", 1);

const node3_1 = new TreeNode(
  0,
  false,
  -1750,
  1630,
  "Al parecer, si mi hijo sigue estudiando puedo mantener mi pensión. Sólo tengo que comprobarlo con una contsnacia de estudios.",
  0
);

const node3_2 = new TreeNode(
  0,
  false,
  -1750,
  1630,
  "Marcaré a Profuturo para que me den más información.",
  0
);

const node4_1 = new TreeNode(
  1,
  false,
  -2320,
  1960,
  "Hola estás llamando a Profuturo, ¿en qué podemos ayudarte?",
  1
);

node4_1.dialogAnchor = { x: 4.8, y: 3.2 };

node4_1.quotes = [
  "“Me tardé años en contactarlos, no encontraba el teléfono y cuando lo encontré era uno erróneo entonces tuve que buscar otra vez, de lo más difícil, y solo me contacto porque es un tema importante!!”",
];

const node4_2 = new TreeNode(
  0,
  false,
  -2320,
  1960,
  "Hola, actualmente recibo la pensión por viudez y orfandad, mi hijo está por cumplir los 18 años, pero leí en internet que si sigue estudiando nos pueden seguir dando la pensión, ¿es correcto?",
  0
);

const node4_3 = new TreeNode(
  1,
  false,
  -2320,
  1960,
  "Así es, para comprobar que sigue estudiando debes de subir al portal de pensionados la constancia de estudios de tu hijo.",
  0
);

node4_3.dialogAnchor = { x: 4.8, y: 3.2 };

const node4_4 = new TreeNode(
  0,
  false,
  -2320,
  1960,
  "¿Dónde está el portal de pensionados? ¿tengo que registrarme?",
  0
);

const node4_5 = new TreeNode(
  1,
  false,
  -2320,
  1960,
  'Así es. Para entrar al portal de pensionados debes de ingresar al portal de Profuturo: profuturo.mx, y en el menú superior del lado derecho de la pantalla vendrá un botón que dice "Registro al portal" tienes que dar clic ahí y después seleccionar el registroa al portal de pensiones.',
  0
);

node4_5.dialogAnchor = { x: 4.8, y: 3.2 };

const node4_6 = new TreeNode(0, false, -2320, 1960, "Muchas gracias.", 0);

const node5_1 = new TreeNode(
  0,
  false,
  -1750,
  1630,
  "Bueno primero pediré la constancia de estudios a la escuela de mi hijo.",
  0
);

node5_1.quotes = [
  "“Que bueno que nos dan diferentes opciones para hacer los trámites, con el portal, ya no tuve que ir a su sucursal.”",
  "“Nunca te dan instrucciones claras de lo que debes de hacer, está confuso y sólo te da incertidumbre que así manejen tu pensión.”",
];

const node5_2 = new TreeNode(
  0,
  false,
  -1750,
  1630,
  "Listo, ya marqué y me comentaron que el día de mañana me la comparten.",
  1
);

const node5_3 = new TreeNode(2, false, -1750, 1630, "Pasa 1 día", 24);

const node5_4 = new TreeNode(
  0,
  false,
  -1750,
  1630,
  "Ya me llegó la constancia de estudios de mi hijo, ahora sí me registraré en el portal de pensionados para subirla.",
  0
);

const node5_5 = new TreeNode(
  0,
  false,
  -1750,
  1630,
  "No recuerdo bien todas las instrucciones que me dijo, pero empezaré entrando a su página web.",
  0
);

const node5_6 = new TreeNode(
  2,
  false,
  -3120,
  2415,
  "Cliente va a su compu eingresa a profuturo.mx",
  0
);

const node5_7 = new TreeNode(
  2,
  false,
  -3120,
  2415,
  "Juego de las compus profuturo",
  0
);

node5_7.game = 17;

const node6_1 = new TreeNode(
  0,
  false,
  -3120,
  2415,
  'Listo, ya entré a su página, algo comentó de un botón que dice "Registro".',
  0
);

node6_1.quotes = [
  "“Nunca he podido entrar a su portal, no sirve de mucho en realidad, mejor atiendan por medios tradicionales o arréglenlo.”",
];

const node6_2 = new TreeNode(2, false, -3120, 2415, "Pasan unos minutos.", 0);

const node6_3 = new TreeNode(
  0,
  false,
  -3120,
  2415,
  "Me tardé, pero ya lo encontré, esto de las computadoras no es mi fuerte. Pero ya seleccioné el registro al portal de pensiones, y ahora me pide información, no sé cuál es mi número de póliza, lo buscaré en los documentos que me dieron en el momento del trámite.",
  1
);

const node6_4 = new TreeNode(
  2,
  false,
  -3120,
  2415,
  "Juego de encuentra tu póliza??",
  0
);

const node7_1 = new TreeNode(
  0,
  false,
  -3120,
  2415,
  "No puedo creer que no me pueda registrar, marcaré a Profuturo para que me apoyen.",
  0
);

node7_1.quotes = [
  "“No pude ingresar al portal, me tardé años y luego su servicio se tarda mucho en solucionar estos temas, me urgía entrar y se tardaron muchísimo...”",
  "“Nos dicen que lo hagamos por el portal, pero no funciona, sólo nos hacen perder más el tiempo, hubiera sido más fácil si me hubieran querido atender por teléfono.”",
];

const node7_2 = new TreeNode(
  1,
  false,
  -4190,
  2990,
  "Hola estás llamando a Profuturo, ¿en qué podemos ayudarte?",
  0
);

node7_2.quotes = [
  "“Se tardaron años en contestarme y me urgía porque tenía que comprar con la fecha límite.”",
];

const node7_3 = new TreeNode(
  0,
  false,
  -4190,
  2990,
  "Hola, me he estado intentando registrar en el portal de pensionados pero me dice que hay un error con mi información, ¿sabes por qué se puede presentar esto?",
  0
);

const node7_4 = new TreeNode(
  1,
  false,
  -4190,
  2990,
  "¿Te estás registrando con tu información?",
  0
);

const node7_5 = new TreeNode(0, false, -4190, 2990, "Así es.", 0);

const node7_6 = new TreeNode(
  1,
  false,
  -4190,
  2990,
  "Para registrarse en el portal, deber de hacerlo con los datos del asegurado, es decir de su esposo.",
  0
);

const node7_7 = new TreeNode(
  0,
  false,
  -4190,
  2990,
  "Con razón, deberían de ponerlo en las instrucciones del registro para que uno no de tantas vueltas.",
  0
);

const node7_8 = new TreeNode(
  1,
  false,
  -4190,
  2990,
  "Muchas gracias por la recomendación la consideraremos.",
  1
);

node7_8.quotes = [
  "“Respondieron súper bien ante la problemática que tenía con el portal, y ya de ahí todo fue súper fácil.”",
];

const node7_9 = new TreeNode(
  2,
  false,
  -4190,
  2990,
  "Juego de llenar documento del asegurado",
  0
);

node7_9.game = 13;

const node8_1 = new TreeNode(
  0,
  false,
  -4950,
  3045,
  "Listo, a pesar de que me tardé un poco ya estoy dentro del portal, ahora, ¿Dónde puedo subir las constancias?",
  0
);

const node8_2 = new TreeNode(
  2,
  false,
  -4950,
  3045,
  "juego de que no encuentra el trámite y que lo busque",
  0
);

node8_2.game = 14;

const node8_2_1 = new TreeNode(
  0,
  false,
  -4950,
  3045,
  "Listo, aquí es dónde las puedo subir, que complicado ha sido esto, pero sí agradezco que no tenga que ir a sucursal o a otro lado a entregarla.",
  0
);

const node8_2_2 = new TreeNode(
  0,
  false,
  -4950,
  3045,
  "Tendré que pedir asesoría, porque no encuentro el menú, llamaré nuevamente.",
  0
);

node8_2_2.quotes = [
  "“El portal, a pesar de que parece ser un canal práctico tiene muchas fallas, deberían de arreglarlo, no se entiende qué es lo que debes de hacer.”",
];

const node8_2_2_1_1 = new TreeNode(
  0,
  false,
  -4950,
  3045,
  "No me contestaron, lo intentaré el día de mañana",
  0
);

const node8_2_2_1_2 = new TreeNode(
  0,
  false,
  -4950,
  3045,
  "Intentaré marcar nuevamente.",
  24
);

const node8_2_2_2_1 = new TreeNode(
  2,
  false,
  -4950,
  3045,
  "Cliente llama, le contestan y le dan la información.",
  0
);

const node8_2_2_2_2 = new TreeNode(
  0,
  false,
  -6348,
  3210,
  "Listo, ya me explicaron dónde picarle. Ya subí las constancias, espero todo esté bien.",
  1
);

node8_2_2_2_2.quotes = [
  "“Subí las constancias pero el sitio se tardó mucho en responder, pero bueno al final lo logré.”",
];

const node8_3 = new TreeNode(
  2,
  false,
  -6348,
  3210,
  "Juego de seleccionar constancia de estudios y jalarlo a la compu??",
  0
);

const node9 = new TreeNode(
  0,
  false,
  -6348,
  3210,
  "Por fin, entiendo que ya con esto me seguirán depositando la pensión, si no veo que me cae marcaré a Profuturo. También ya me anoté las fechas de cuándo tengo que subirlo.",
  0
);

const node10 = new TreeNode(2, false, -6348, 3210, "Fin", 0);

node10.isLast = true;
node10.ending = 0;

node1.descendants = [
  {
    node: node2_1,
    hint: "Marcaré a Profuturo y preguntaré",
  },
  {
    node: node3_1,
    hint: "Buscaré en internet",
  },
];

node2_1.descendants = [
  {
    node: node2_2,
    hint: "Continuar",
  },
];

node2_2.descendants = [
  {
    node: node2_3,
    hint: "Continuar",
  },
];

node2_3.descendants = [
  {
    node: node2_4,
    hint: "Continuar",
  },
];

node2_4.descendants = [
  {
    node: node2_5,
    hint: "Continuar",
  },
];

node2_5.descendants = [
  {
    node: node2_6,
    hint: "Continuar",
  },
];

node2_6.descendants = [
  {
    node: node2_7,
    hint: "Continuar",
  },
];

node2_7.descendants = [
  {
    node: node2_8,
    hint: "Continuar",
  },
];

node2_8.descendants = [
  {
    node: node5_1,
    hint: "Continuar",
  },
];

node3_1.descendants = [
  {
    node: node3_2,
    hint: "Continuar",
  },
];

node3_2.descendants = [
  {
    node: node4_1,
    hint: "Continuar",
  },
];

node4_1.descendants = [
  {
    node: node4_2,
    hint: "Continuar",
  },
];

node4_2.descendants = [
  {
    node: node4_3,
    hint: "Continuar",
  },
];

node4_3.descendants = [
  {
    node: node4_4,
    hint: "Continuar",
  },
];

node4_4.descendants = [
  {
    node: node4_5,
    hint: "Continuar",
  },
];

node4_5.descendants = [
  {
    node: node4_6,
    hint: "Continuar",
  },
];

node4_6.descendants = [
  {
    node: node5_1,
    hint: "Continuar",
  },
];

node5_1.descendants = [
  {
    node: node5_2,
    hint: "Continuar",
  },
];

node5_2.descendants = [
  {
    node: node5_3,
    hint: "Continuar",
  },
];

node5_3.descendants = [
  {
    node: node5_4,
    hint: "Continuar",
  },
];

node5_4.descendants = [
  {
    node: node5_5,
    hint: "Continuar",
  },
];

node5_5.descendants = [
  {
    node: node5_6,
    hint: "Continuar",
  },
];

node5_6.descendants = [
  {
    node: node5_7,
    hint: "Continuar",
  },
];

node5_7.descendants = [
  {
    node: node6_1,
    hint: "Continuar",
  },
];

node6_1.descendants = [
  {
    node: node6_2,
    hint: "Continuar",
  },
];

node6_2.descendants = [
  {
    node: node6_3,
    hint: "Continuar",
  },
];

//node6_4
node6_3.descendants = [
  {
    node: node7_1,
    hint: "Continuar",
  },
];

node6_4.descendants = [
  {
    node: random([node7_1, node7_1, node8_1]),
    hint: "Continuar",
  },
];

node7_1.descendants = [
  {
    node: node7_2,
    hint: "Continuar",
  },
];

node7_2.descendants = [
  {
    node: node7_3,
    hint: "Continuar",
  },
];

node7_3.descendants = [
  {
    node: node7_4,
    hint: "Continuar",
  },
];

node7_4.descendants = [
  {
    node: node7_5,
    hint: "Continuar",
  },
];

node7_5.descendants = [
  {
    node: node7_6,
    hint: "Continuar",
  },
];

node7_6.descendants = [
  {
    node: node7_7,
    hint: "Continuar",
  },
];

node7_7.descendants = [
  {
    node: node7_8,
    hint: "Continuar",
  },
];

node7_8.descendants = [
  {
    node: node7_9,
    hint: "Continuar",
  },
];

node7_9.descendants = [
  {
    node: node8_1,
    hint: "Continuar",
  },
];

node8_1.descendants = [
  {
    node: node8_2,
    hint: "Continuar",
  },
];

node8_2.descendants = [
  {
    node: random([node8_2_1, node8_2_2]),
    hint: "Continuar",
  },
];

//node8_3
node8_2_1.descendants = [
  {
    node: node9,
    hint: "Continuar",
  },
];

node8_2_2.descendants = [
  {
    node: random([node8_2_2_1_1, node8_2_2_2_1]),
    hint: "Continuar",
  },
];

node8_2_2_1_1.descendants = [
  {
    node: node8_2_2_1_2,
    hint: "Continuar",
  },
];

node8_2_2_1_2.descendants = [
  {
    node: node8_2_2_2_1,
    hint: "Continuar",
  },
];

node8_2_2_2_1.descendants = [
  {
    node: node8_2_2_2_2,
    hint: "Continuar",
  },
];

//node8_3
node8_2_2_2_2.descendants = [
  {
    node: node9,
    hint: "Continuar",
  },
];

node8_3.descendants = [
  {
    node: node9,
    hint: "Continuar",
  },
];

node9.descendants = [
  {
    node: node10,
    hint: "Continuar",
  },
];

export default node1;
