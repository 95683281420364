import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userName: "",
    userWorlds: [],
    session: false,
    role: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.userName = action.payload.userName;
      state.role = action.payload.role;
      state.session = true;
    },
    removeUser: (state) => {
      state.userName = "";
      state.role = null;
      state.session = false;
    },
    setWorldScore: (state, action) => {
      state.userWorlds[action.payload.index].score = action.payload.score;
      state.userWorlds[action.payload.index].time = action.payload.time;
    },
    setWorldStatus: (state, action) => {
      state.userWorlds[action.payload.index].active = action.payload.active;
    },
    setWorlds: (state, action) => {
      state.userWorlds = action.payload;
    },
  },
});

export const { setUser, removeUser, setWorldScore, setWorldStatus, setWorlds } =
  userSlice.actions;

export default userSlice.reducer;
