import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import map from "../../assets/registry/map.js";
import Back from "../../baseComponents/Back";
import Dialog from "../../baseComponents/Dialog";
import {
  setCurrentNode,
  resetNode,
} from "../../redux/features/registry/registry";
import { Spring } from "react-spring";
import {
  AnimatedSprite,
  Container,
  Sprite,
  TilingSprite,
  useApp,
} from "@inlet/react-pixi/animated";
import tableWoman from "../../assets/anim/table woman.json";
import phoneGuyFiles from "../../assets/anim/phone guy files.json";
import { easeCubicInOut } from "d3-ease";
import { setTransition } from "../../redux/features/transition";
import GameContainer from "../../baseComponents/GameContainer/Index";
import { setSection } from "../../redux/features/general.js";
import Avatar from "./components/Avatar";
import TimeCount from "./components/TimeCount.js";
import laptop from "../../assets/anim/laptop.json";
import phoneGuy from "../../assets/anim/phone guy.json";
import trees from "../../assets/map/trees/tree1.png";
import TitleContainer from "../../baseComponents/TitleContainer.js";
import image from "../../assets/registry/intrucciones_juego01.png";
import ending1 from "../../assets/registry/textoimpacto.png";
import useWindowSize from "../../utils/useWindowSize";
import Quotes from "../../baseComponents/Quotes.js";
import { addTime, resetTime } from "../../redux/features/registry/time";
import * as PIXI from "pixi.js";
import rasterizeSVG from "../../utils/rasterizeSVG.js";
import {
  setAudio,
  resetAudio,
  interruptAudio,
} from "../../redux/features/audio.js";
import audio1 from "../../assets/audio/Traspaso- Sin nombre de AFORE ‐ Made with Clipchamp.mp3";
import audio2 from "../../assets/audio/APOVOL 2 ‐ Made with Clipchamp.mp3";
import audio3 from "../../assets/audio/Retiro por Desempleo ‐ Made with Clipchamp.mp3";
import audio4 from "../../assets/audio/Actualización de datos 1 ‐ Made with Clipchamp.mp3";
import search from "../../assets/anim/laptop search.json";
import LottiePixiLoader from "../../utils/LottiePixiLoader.js";

export default function Registry() {
  const spring = { mass: 1, tension: 100, friction: 50 };

  const Animated = () => {
    const { info } = useSelector((state) => state.info);
    const frames = LottiePixiLoader(
      [174, 358, 330, 635],
      [tableWoman, phoneGuy, phoneGuyFiles, search]
    );
    const scale = window.innerWidth / 1536;
    const [showTitle, setShowTitle] = useState(0);
    const [titleImage, setTitleImage] = useState(image);
    const [showAvatar, setShowAvatar] = useState(false);
    const [avatarStart, setAvatarStart] = useState(true);
    const [showQuotes, setShowQuotes] = useState(1);
    const audios = [audio1, audio2, audio3, audio4];
    const [isAnimationsPlaying, setAnimationsPlaying] = useState(false);
    const dispatch = useDispatch();

    const { currentNode, anchor } = useSelector((state) => state.registry);
    const lastNode = useRef(currentNode);
    const endingImage = [ending1];

    useEffect(() => {
      /*
      dispatch(setTransition(false));

      setTimeout(() => {
        setShowTitle(1);
      }, 1000);
      */
      return () => {
        dispatch(resetNode());
        //dispatch(resetTime());
      };
    }, []);

    useEffect(() => {
      if (!!frames.length) {
        setTimeout(() => {
          dispatch(setTransition(false));

          setTimeout(() => {
            if (!showTitle) setShowTitle(1);
          }, 1000);
        }, 500);
      }
    }, [frames]);

    useEffect(() => {
      if (currentNode.isLast) {
        if (typeof currentNode.ending === "number") {
          setTitleImage(endingImage[currentNode.ending]);
        }
        setShowTitle(info ? 0 : 1);
      }
      if (typeof currentNode.audio === "number") {
        dispatch(setAudio(audios[currentNode.audio]));
      } else {
        dispatch(resetAudio());
      }
    }, [currentNode, info]);

    const handleAudio = (interrupted) => {
      //console.log(interrupted);
      dispatch(interruptAudio(interrupted));
    };

    const onAction = (id) => {
      dispatch(setCurrentNode(currentNode.descendants[id].node || 0));
      lastNode.current = currentNode;
      setShowQuotes(1);
    };

    const handleBack = () => {
      dispatch(setTransition(true));
      setTimeout(() => {
        dispatch(setSection("menu"));
        dispatch(resetTime());
      }, 1000);
    };

    const handleDialogClose = () => {
      setShowQuotes(0);
    };

    const onCloseGame = (stay, gameId = 0) => {
      //console.log(gameId);
      dispatch(
        setCurrentNode(
          stay ? lastNode.current : currentNode.descendants[gameId].node
        )
      );
    };

    return (
      <Spring
        to={{
          x: currentNode.anchor.x * scale,
          y: currentNode.anchor.y * scale,
          anchor: [window.innerWidth / 2.1, window.innerHeight / 2],
        }}
        config={{ duration: 1000, easing: easeCubicInOut }}
      >
        {(props) => (
          <Container>
            <Container position={props.anchor}>
              <Container {...props} pivot={[0, scale * 1417 * 2.5]}>
                <Sprite
                  image={rasterizeSVG(map, [
                    scale * 2306 * 2.5,
                    scale * 1417 * 2.5,
                  ])}
                />
                {!!frames.length && (
                  <>
                    <Container position={[715 * scale, 2490 * scale]}>
                      <AnimatedSprite
                        position={[0, 0]}
                        textures={frames[3]}
                        isPlaying={isAnimationsPlaying}
                        initialFrame={0}
                        animationSpeed={0.5}
                      />
                    </Container>
                    <Container position={[2419 * scale, 687 * scale]}>
                      <AnimatedSprite
                        position={[0, 0]}
                        textures={frames[0]}
                        isPlaying={isAnimationsPlaying}
                        initialFrame={0}
                        animationSpeed={0.3}
                      />
                    </Container>
                    <Container position={[2625 * scale, 1190 * scale]}>
                      <AnimatedSprite
                        position={[0, 0]}
                        textures={frames[1]}
                        isPlaying={isAnimationsPlaying}
                        initialFrame={0}
                        animationSpeed={0.3}
                      />
                    </Container>
                    <Container position={[1950 * scale, 814 * scale]}>
                      <AnimatedSprite
                        position={[0, 0]}
                        textures={frames[2]}
                        isPlaying={isAnimationsPlaying}
                        initialFrame={0}
                        animationSpeed={0.5}
                      />
                    </Container>
                    <Container
                      position={[3221 * scale, 242 * scale]}
                      scale={[-1, 1]}
                    >
                      <AnimatedSprite
                        position={[0, 0]}
                        textures={frames[1]}
                        isPlaying={isAnimationsPlaying}
                        initialFrame={0}
                        animationSpeed={0.3}
                      />
                    </Container>
                  </>
                )}
                <Sprite
                  image={trees}
                  position={[925 * scale, 2925 * scale]}
                  scale={scale}
                />
              </Container>
            </Container>
            {showAvatar && (
              <Container position={props.anchor} scale={scale}>
                <Avatar
                  anchor={currentNode.anchor}
                  prevDirection={lastNode.current.direction}
                  direction={currentNode.direction}
                  begin={avatarStart}
                />
              </Container>
            )}
            <Back click={handleBack} />
            {showAvatar && (
              <Quotes
                quotes={currentNode.quotes}
                visible={showQuotes}
                ageRange={[20, 40]}
                titleColor="#D67D17"
                position={
                  (currentNode.direction && currentNode.type === 0) ||
                  (!currentNode.direction && currentNode.type === 1)
                }
              />
            )}
            {typeof currentNode.game !== "number" &&
              showAvatar &&
              !currentNode.isLast && (
                <Dialog
                  id={currentNode}
                  {...currentNode.dialogAnchor}
                  direction={currentNode.direction}
                  delay={
                    currentNode.anchor.x === lastNode.current.anchor.x &&
                    currentNode.anchor.y === lastNode.current.anchor.y
                      ? 0
                      : 2000
                  }
                  message={currentNode.message}
                  buttons={
                    currentNode.descendants
                      ? currentNode.descendants.map((e, i) => {
                          e.key = i;
                          //console.log(e);
                          return e.hint;
                        })
                      : []
                  }
                  type={currentNode.type}
                  action={onAction}
                  currentNode={currentNode}
                  addTime={addTime}
                  onClose={handleDialogClose}
                  buttonsActive={true || typeof currentNode.audio !== "number"}
                />
              )}
            <GameContainer
              descendants={currentNode.descendants}
              id={currentNode.game}
              lastId={lastNode.current && lastNode.current.game}
              close={(stay, gameId) => onCloseGame(stay, gameId)}
              back={() => handleBack()}
            />
            <TitleContainer
              image={titleImage}
              scale={showTitle}
              isLast={currentNode.isLast}
              onVideoVisible={() => handleAudio(true)}
              hasEndingMessage={typeof currentNode.ending === "number"}
              commentPos={0}
              close={() => {
                if (currentNode.isLast) handleBack();
                setAnimationsPlaying(true);
                setShowTitle(0);
                setShowAvatar(true);
                setAvatarStart(false);
                handleAudio(false);
              }}
              buttonsPosition={[
                window.innerWidth / 4.8,
                window.innerHeight / 2 + 200 * scale,
              ]}
              worldType={0}
            />
            <TimeCount setFinalScore={currentNode.isLast} />
          </Container>
        )}
      </Spring>
    );
  };

  return <Animated />;
}
