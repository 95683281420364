import { Container, Graphics, Sprite, Text } from "@inlet/react-pixi/animated";
import { TextStyle } from "pixi.js";
import { Spring } from "@react-spring/core";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import gamesBg from "../assets/games/games_bg.png";
import { easeCubicInOut, easeQuadInOut } from "d3-ease";
import { useDispatch, useSelector } from "react-redux";
import { setVideo, setPlayed } from "../redux/features/video";
import { resetComment } from "../redux/features/general";
import { addComment } from "../services/users";

const Info = memo(
  ({
    image,
    scale = 1,
    close,
    isLast,
    video,
    onVideoVisible,
    buttonsPosition,
    hasComment = true,
    hasEndingMessage,
    worldType,
  }) => {
    const spring = { mass: 6, tension: 1000, friction: 100 };
    const relativeScale = window.innerWidth / 1536;
    const Animated = () => {
      const { played } = useSelector((state) => state.video);
      const { comment } = useSelector((state) => state.general);
      const { userName } = useSelector((state) => state.user);
      const [isBtnActive, setBtnActive] = useState(false);
      const [firstRender, setFirstRender] = useState(3);
      const text = useRef();
      const text2 = useRef();
      const [textWidth, setTextWidth] = useState();
      const [text2Width, setText2Width] = useState();
      const [visible, setVisible] = useState(scale);
      const [isVideoVisible, setVideoVisible] = useState(false);
      const [isCommentVisible, setCommentVisible] = useState(!hasEndingMessage); //change
      const mask = useRef();
      const dispatch = useDispatch();

      const label = useCallback((g) => {
        g.clear();
        g.beginFill(0xe8993d, 1);
        g.drawRoundedRect(0, 0, textWidth + 50, 35, 40);
        g.endFill();
      });

      const labelBack = useCallback((g) => {
        g.clear();
        g.beginFill(0xd67d17, 1);
        g.drawRoundedRect(0, 0, textWidth + 50, 35, 40);
        g.endFill();
      });

      const label2 = useCallback((g) => {
        g.clear();
        g.beginFill(0xe8993d, 1);
        g.drawRoundedRect(0, 0, text2Width + 50, 35, 40);
        g.endFill();
      });

      const labelBack2 = useCallback((g) => {
        g.clear();
        g.beginFill(0xd67d17, 1);
        g.drawRoundedRect(0, 0, text2Width + 50, 35, 40);
        g.endFill();
      });

      useEffect(() => {
        return () => {
          setVisible(1);
        };
      }, []);

      useEffect(() => {
        setTextWidth(text.current.width);
        setText2Width(text2.current.width);
      });

      useEffect(() => {
        setVisible(scale);
        if (video)
          dispatch(setVideo({ url: video, active: !!scale, visible: false }));
      }, [scale]);

      useEffect(() => {
        if (firstRender > 0) {
          setFirstRender(firstRender - 1);
          //console.log(isLast, visible);
        }
      }, [firstRender]);

      const btnDown = (id) => {
        setBtnActive(true);
      };

      const back = useCallback((g) => {
        g.clear();
        g.beginFill(0xffffff, 0.7);
        g.drawRect(0, 0, window.innerWidth, window.innerHeight);
        g.endFill();
      });

      //console.log(onVideoVisible);
      const btnUp = (id) => {
        setTimeout(() => {
          setBtnActive(false);
          //setVisible(0);
        }, 50);
        if (!isLast && video && !isVideoVisible) {
          //console.log("a");
          dispatch(setVideo({ url: video, active: true, visible: true }));
          setVideoVisible(true);
          //console.log(onVideoVisible);
          onVideoVisible();
        } else if (hasComment && isLast && !isCommentVisible) {
          //console.log("b");
          //dispatch(setComment(true))
          setCommentVisible(true);
          //onCommentVisible()
        } else if (hasComment && isLast && isCommentVisible) {
          //console.log("c");
          if (!!comment) {
            addComment(userName, comment, worldType).then((res) => {
              if (res.data.StatusCode === 200 || true) {
                setTimeout(() => {
                  setVisible(0);
                }, 500);
                setTimeout(() => {
                  dispatch(resetComment());
                  close();
                  setVisible(1);
                }, 1000);
              }
            });
          }
        } else {
          setTimeout(() => {
            setVisible(0);
            //dispatch(setVideo({ url: video, active: false, visible: true }));
          }, 500);
          setTimeout(() => {
            close();
            setVisible(1);
            //setVideoVisible(false);
            //dispatch(setVideo({ url: video, active: false, visible: false }));
            //dispatch(setPlayed(false));
          }, 1000);
        }
      };

      return (
        <Spring
          {...(visible && {
            from: {
              x: 0,
              y: 0,
              scale: visible ? 0 : 1,
              alpha: visible ? 0 : 1,
            },
          })}
          to={{
            x: 0,
            y: 0,
            scale: visible ? 1 : 0,
            alpha: visible ? 1 : 0,
          }}
          config={spring}
        >
          {(props) => (
            <>
              <Graphics
                draw={(g) => back(g)}
                alpha={props.alpha}
                pivot={[window.innerWidth / 2, window.innerHeight / 2]}
                position={[window.innerWidth / 2, window.innerHeight / 2]}
                interactive={visible}
              />
              <Container
                alpha={props.alpha}
                scale={props.scale}
                pivot={[window.innerWidth / 2, window.innerHeight / 2]}
                position={[window.innerWidth / 2, window.innerHeight / 2]}
                mask={mask.current}
              >
                <Sprite
                  ref={mask}
                  image={gamesBg}
                  interactive
                  anchor={0.5}
                  scale={0.7 * relativeScale}
                  position={[window.innerWidth / 2, window.innerHeight / 2]}
                />
                <Sprite
                  image={gamesBg}
                  interactive
                  alpha={props.alpha}
                  scale={0.7 * relativeScale}
                  anchor={0.5}
                  position={[window.innerWidth / 2, window.innerHeight / 2]}
                />

                <Spring
                  to={{
                    containerX:
                      isVideoVisible || (isLast && isCommentVisible)
                        ? -window.innerWidth
                        : 0,
                    secondButton: isLast ? 1 : played ? 1 : 0,
                  }}
                  config={{ duration: 800, easing: easeQuadInOut }}
                >
                  {(props) => (
                    <Container x={props.containerX}>
                      <Sprite
                        image={image}
                        interactive
                        anchor={0.5}
                        scale={0.7 * relativeScale}
                        position={[
                          window.innerWidth / 2,
                          window.innerHeight / 2,
                        ]}
                      />

                      <Container
                        position={
                          buttonsPosition || [
                            0.435 * window.innerWidth,
                            0.5 * window.innerHeight + 240 * relativeScale,
                          ]
                        }
                        scale={relativeScale}
                        interactive="true"
                        cursor="pointer"
                        pointerdown={btnDown}
                        pointerup={btnUp}
                      >
                        <Graphics draw={(g) => labelBack(g)} />
                        <Container
                          x={isBtnActive ? props.x : -2}
                          y={isBtnActive ? props.y : -4}
                        >
                          <Graphics draw={(g) => label(g)} />
                          <Text
                            ref={text}
                            text={
                              isLast && !hasComment
                                ? "Regresar al mapa principal"
                                : "Continuar"
                            }
                            x={25}
                            y={6}
                            style={
                              new TextStyle({
                                fontFamily: "Poppins-Bold",
                                fontSize: 18,
                                fill: "#fff",
                              })
                            }
                          />
                        </Container>
                      </Container>

                      <Container
                        position={[
                          window.innerWidth +
                            (window.innerWidth / 2 - (textWidth + 50) / 2),
                          window.innerHeight / 2 + window.innerWidth / 6.7,
                        ]}
                        interactive="true"
                        cursor="pointer"
                        pointerdown={btnDown}
                        pointerup={btnUp}
                        scale={relativeScale}
                      >
                        <Container
                          alpha={props.secondButton}
                          scale={props.secondButton}
                        >
                          <Graphics draw={(g) => labelBack2(g)} />
                          <Container
                            x={isBtnActive ? props.x : -2}
                            y={isBtnActive ? props.y : -4}
                          >
                            <Graphics draw={(g) => label2(g)} />
                            <Text
                              ref={text2}
                              text={isLast ? "Enviar" : "Continuar"}
                              x={25}
                              y={6}
                              style={
                                new TextStyle({
                                  fontFamily: "Poppins-Bold",
                                  fontSize: 18,
                                  fill: "#fff",
                                })
                              }
                            />
                          </Container>
                        </Container>
                      </Container>
                    </Container>
                  )}
                </Spring>
              </Container>
            </>
          )}
        </Spring>
      );
    };
    return <Animated />;
  },
  (a, b) => a === b
);

export default Info;
