import TreeNode from "../../../structures/TreeNode";
import random from "../../../utils/random";

/**
  Example
  
 *  Node
    const node1 = new TreeNode(0, false, -790, 590, "¿En qué afore estoy?", 2);

 *  Game node
    const node2 = new TreeNode(0, false, -790, 590); //juego 0

 *  Set Descendants
    node1.descendants = [
      { node: node1_2, hint: "Preguntaré en RH de mi empresa" },
      { node: node1_3, hint: "Revisaré en internet" },
    ];

 *  Set Game
    node2.game = 0;
 */

const node1 = new TreeNode(
  0,
  false,
  -1052,
  772,
  "Ahora que me quedé sin empleo y tampoco encuentro uno, debo investigar si hay alguna ayuda por parte de instituciones o gobierno.",
  0
);

node1.quotes = ["“El estrés de no ser autosuficiente es lo peor”"];

const node1_2 = new TreeNode(
  2,
  false,
  -1052,
  772,
  "Un amigo que está en una situación similar me comentó que él hizo un retiro por desempleo de su Afore. No recuerdo en cuál estoy, pero recuerdo que alguna vez me puse en contacto con Profuturo.",
  1
);

node1_2.quotes = [
  "“Con esto podré darles algo a mis hijos en Navidad”",
  "“Me enteré por un amigo que podía hacer retiros en mi Afore si es que lo necesitaba”",
  "“Cuando perdí mi empleo, lo único que me preocupaba era la inscripción de mi hija a la escuela”",
];

const node1_3 = new TreeNode(
  2,
  false,
  -1405,
  980,
  "Encontré que puedo hacer un retiro de mi Afore. No recuerdo en cuál estoy, pero recuerdo que alguna vez me puse en contacto con Profuturo.",
  0
);

//const node2 = new TreeNode(0, false, -1355, 950, "¿En qué Afore estoy?", 2);

const node2_2 = new TreeNode(
  2,
  false,
  -1405,
  980,
  "Encontré una página que se llama e-sar, ahí consulté y vi que mi Afore es Profuturo. Buscaré cómo contactarlos.",
  0
);

//const node3 = new TreeNode(0, false, -1485, 1025, "Profuturo es mi Afore", 2);

const node4 = new TreeNode(
  0,
  false,
  -1405,
  980,
  "En su página encontré el teléfono del call center y las sucursales que están disponibles.",
  1
);

const node4_1 = new TreeNode(
  0,
  false,
  -2177,
  1263,
  "Veo que sólo hay citas para dentro de dos semanas, no sé qué hacer.",
  1
);

node4_1.quotes = ["“Échame la mano, no tengo ni para comer”"];

const node4_1_1 = new TreeNode(2, false, -2177, 1263, "Te atienden rápido.", 0);

const node4_1_2 = new TreeNode(
  2,
  false,
  -2177,
  1263,
  "Te atienden en una hora.",
  1
);

/*
const node5 = new TreeNode(
  1,
  false,
  -2687,
  1100,
  "¿Es un cliente registrado?",
  0
);

node5.quotes = [
  "“Cuando vi que podía hacer un retiro por desempleo, ni sabía en que Afore estaba, cuando supe, tuve que registrarme, lo cual hizo que todo fuera más tardado, no entienden que tenemos una necesidad...”",
  "“La persona que me atendió fue muy amable, me supo explicar todo y me resolvió dudas. ¡El servicio está para aplaudirse!”",
];
*/

const node5_1 = new TreeNode(2, false, -2687, 1100, "Llamando.", 0);

const node5_1_1 = new TreeNode(
  1,
  false,
  -2687,
  1100,
  "Hola, estás marcando a Profuturo, ¿en qué te puedo apoyar?",
  0
);

node5_1_1.dialogAnchor = { x: 5 };

const node5_1_2 = new TreeNode(
  0,
  false,
  -2687,
  1100,
  "Hola, me gustaría hacer el trámite de Retiro por Desempleo, ¿me podría indicar cómo hacerlo?",
  0
);

const node5_1_3 = new TreeNode(
  1,
  false,
  -2687,
  1100,
  "Claro, ¿me podrías compartir tu CURP?",
  0
);

node5_1_3.dialogAnchor = { x: 5 };

/*

const node5_2_1 = new TreeNode(
  1,
  false,
  -2687,
  1100,
  "Hola, estás llamando a Profuturo ¿en qué puedo ayudarte?",
  0
);

node5_2_1.quotes = [
  "“Me atendieron rápido en sucursal y me dieron asesoría”",
  "“La información que dan para hacer el tramite no es clara y no es de mucha ayuda, solo me confundí”",
];

const node5_2_1_1 = new TreeNode(
  0,
  false,
  -2687,
  1100,
  "Hola, me gustaría hacer el trámite de Retiro por Desempleo, ¿me podría indicar cómo hacerlo?",
  0
);

const node5_2_1_2 = new TreeNode(
  1,
  false,
  -2687,
  1100,
  "Claro, ¿me podrías compartir tu CURP?",
  0
);

const node5_2_1_3 = new TreeNode(0, false, -2687, 1100, "Claro", 0);

*/

const node5_2_1_4 = new TreeNode(
  1,
  false,
  -2687,
  1100,
  "Validando tu información veo que ya cuentas con tu Expediente para hacer el trámite de retiro , por lo que sólo haría falta generar una cita en sucursal.",
  0
);

node5_2_1_4.dialogAnchor = { x: 5 };

const node5_2_1_5 = new TreeNode(
  0,
  false,
  -2687,
  1100,
  "Perfecto ¿me podrías apoyar agendando una?",
  0
);

const node5_2_1_6 = new TreeNode(
  1,
  false,
  -2687,
  1100,
  "Claro, tenemos disponibilidad en una semana ¿está bien?",
  0
);

const node5_2_2 = new TreeNode(
  1,
  false,
  -2687,
  1100,
  "Validando tu información veo que estás registrado con Profuturo pero no tienes tu expediente completo y es necesario para hacer el trámite de retiro por desempleo, por lo que sólo haría falta generar una cita en sucursal.",
  0
);

node5_2_2.quotes = ["“Tardan mucho en darnos un cita”"];

const node5_3 = new TreeNode(
  1,
  false,
  -2687,
  1100,
  "Hola, estás llamando a Profuturo ¿en qué puedo ayudarte?",
  0
);

node5_3.quotes = [
  "“No entiendo porqué si necesito hacer un retiro de mi Afore, me están pidiendo que haga un trámite antes”",
];

const node5_3_1 = new TreeNode(
  0,
  false,
  -2687,
  1100,
  "Hola, me gustaría hacer el trámite de Retiro por Desempleo, ¿me podría indicar cómo hacerlo?",
  0
);

const node5_3_2 = new TreeNode(
  1,
  false,
  -2687,
  1100,
  "Claro, ¿me podrías compartir tu CURP?",
  0
);
//here (que aparezca botón “Proporcionar CURP”.
const node5_3_3 = new TreeNode(0, false, -2687, 1100, "Claro", 0);

const node5_3_4 = new TreeNode(
  1,
  false,
  -2687,
  1100,
  "Validando tu información veo que no eres nuestro cliente, para hacer el trámite de retiro primero tendremos que hacer el trámite de traspaso por medio de Afore Móvil. Ya que haya concluido, tendremos que generar tu Expediente de Identificación en sucursal y posterior ya hacer el trámite de retiro por desempleo, te mando por correo los documentos que necesitas para hacer los tres trámites.",
  0
);

const node5_3_5 = new TreeNode(
  0,
  false,
  -2687,
  1100,
  "¿Todo eso tengo que hacer? ¿No hay una forma más rápida de hacerlo?",
  0
);

const node5_3_6 = new TreeNode(
  1,
  false,
  -2687,
  1100,
  "Temo que no, pero estamos aquí para apoyarte en lo que necesites.",
  0
);

node5_3_6.dialogAnchor = { x: 5 };

const node5_3_7 = new TreeNode(
  0,
  false,
  -2687,
  1100,
  "Muchas gracias, empezaré con el trámite de cambio de Afore.",
  0
);

const node5_3_8 = new TreeNode(
  2,
  false,
  -2687,
  1100,
  "Se cuelga la llamada.",
  0
);

const node5_3_9 = new TreeNode(
  0,
  false,
  -2137,
  1245,
  "No puedo creer que tengo que hacer tantas cosas para hacer un retiro de mi Afore, pero bueno necesito el dinero así que ni modo. Ya bajé la app, empezaré con el trámite.",
  0
);

/*
const node6 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "¿Es un cliente registrado?",
  2
);
*/

const node6_2 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "Hola, tengo una cita para hacer el trámite de retiro por desempleo.",
  24 * 14
);

node6_2.dialogAnchor = { y: 2 };

const node6_2a_1 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Perfecto, por favor pase con el asistente para ser atendido.",
  0
);

node6_2a_1.dialogAnchor = { y: 2 };

const node6_2a_2 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Buen día. Entiendo que viene a hacer un retiro por desempleo, ¿me puede compartir sus documentos por favor?",
  0
);

const node6_2_0_1 = new TreeNode(0, false, -3700, 1420, "Aquí están.", 0);

const node6_2_0_2 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Veo que no cuenta con su Expediente, es necesario para hacer el trámite de retiro. Para empezar, vamos a validar que todo esté en orden con tus datos y CURP.",
  0
);

const node6_2_1 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "Hola, tengo una cita para hacer el trámite de retiro por desempleo.",
  24 * 14
);

node6_2_1.dialogAnchor = { y: 2 };

const node6_2_1a_1 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Perfecto, por favor pase con el asistente para ser atendido.",
  0
);

node6_2_1a_1.dialogAnchor = { y: 2 };

const node6_2_1a_2 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Buen día. Entiendo que viene a hacer un retiro por desempleo, ¿me puede compartir sus documentos por favor?",
  0
);

const node6_2_1_1 = new TreeNode(0, false, -3700, 1420, "Aquí están", 0);

const node6_2_1_2 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Todo está en orden y podemos comenzar con el trámite, ¿me podrías compartir tus documentos?",
  0
);

const node6_3 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Buen día. Entiendo que viene a hacer un retiro por desempleo, ¿me puede compartir sus documentos por favor?",
  0
);

const node6_3_1 = new TreeNode(0, false, -3700, 1420, "Aquí están.", 0);

const node6_3_2 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Validando tu información veo que no eres nuestro cliente, para hacer el trámite de retiro primero tendremos que hacer el trámite de traspaso. Ya que haya concluido, tendremos que generar tu Expediente de Identificación y posterior ya hacer el trámite de retiro por desempleo.",
  0
);
//here (que aparezca botón “Proporcionar CURP”.
const node6_3_3 = new TreeNode(
  0,
  false,
  -3700,
  1420,
  "¿Todo eso tengo que hacer? ¿No hay una forma más rápida de hacerlo?",
  0
);

const node6_3_4 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Temo que no, pero podemos hacer el trámite de traspaso de una vez.",
  0
);

const node6_3_5 = new TreeNode(2, false, -3700, 1420, "Una hora después.", 1);

const node6_3_6 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Listo, concluimos el trámite, en 28 días hábiles podrá ver la resolución, le llegará una notificación por correo, ya que haya sido aceptado regrese para hacer el Expediente.",
  0
);

const node6_3_7 = new TreeNode(
  0,
  false,
  -3700,
  1420,
  "Perfecto, estaré pendiente, gracias.",
  0
);

const node6_3_8 = new TreeNode(
  2,
  false,
  -2177,
  1263,
  "Pasan 28 días.",
  24 * 28
);

const node6_3_9 = new TreeNode(
  0,
  false,
  -2177,
  1263,
  "Ya vi en la app que mi trámite concluyó exitosamente. Tengo que ir nuevamente a sucursal, estoy revisando en el portal y no hay disponibilidad mas que la siguiente semana.",
  0
);

const node7 = new TreeNode(2, false, -3215, 1394, "7 días después.", 7 * 24);

const node7_1 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Hola bienvenido a Profuturo, ¿tienes agendada una cita?",
  0
);

node7_1.dialogAnchor = { y: 2 };

node7_1.quotes = [
  "“Me urge el dinero y al final se tardan años, no tienen nada de consideración con sus clientes, solo nos ponen trabas y trabas para sacar NUESTRO dinero”",
];

const node7_1_1 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "Sí, agendé cita para hacer mi expediente y luego mi retiro por desempleo.",
  0
);

const node7_1_2 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Perfecto, aquí la tengo registrada, ¿tienes todos los documentos para el trámite de Expediente?",
  0
);

node7_1_2.dialogAnchor = { y: 2 };

const node7_1_3 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "Sí, los traigo todos.",
  0
);

const node7_1_4 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Perfecto, por favor pase con el asistente para ser atendido.",
  0
);

node7_1_4.dialogAnchor = { y: 2 };

const node7_1_5 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Buen día. Entiendo que viene a hacer su trámite de Expediente, ¿me puede compartir sus documentos por favor?",
  0
);

const node7_1_6 = new TreeNode(0, false, -3700, 1420, "Aquí están.", 0);

const node7_1_7 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Vamos a validar que todo esté en orden con tus datos y CURP.",
  0
);

const node7_2 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Hola bienvenido a Profuturo, ¿tienes agendada una cita?",
  24
);

node7_2.dialogAnchor = { y: 2 };

node7_2.quotes = [
  "“Me urge el dinero y al final se tardan años, no tienen nada de consideración con sus clientes, solo nos ponen trabas y trabas para sacar NUESTRO dinero”",
];

const node7_2_2 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "No, pero vengo a hacer mi expediente y luego mi retiro por desempleo.",
  0
);

const node7_2_3 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Muy bien, ¿tienes todos los documentos?",
  0
);

node7_2_3.dialogAnchor = { y: 2 };

const node7_2_4 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "Sí, los traigo todos.",
  0
);

const node7_2_5 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Perfecto, por favor  tendrás  que esperar a que una de las asistentes se desocupe, ya que se da prioridad a los clientes con cita.",
  0
);

node7_2_5.dialogAnchor = { y: 2 };

const node7_2_6 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "De acuerdo, muchas gracias.",
  0
);

const node7_2_6_1 = new TreeNode(0, false, -3215, 1394, "Una hora después.", 1);

const node7_2_7 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Buen día. Entiendo que viene a hacer su trámite de Expediente, ¿me puede compartir sus documentos por favor?",
  1
);

const node7_2_8 = new TreeNode(0, false, -3700, 1420, "Aquí están.", 1);

const node7_2_9 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Vamos a validar que todo esté en orden con tus datos y CURP.",
  1
);

const node7_3 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Vamos a empezar el trámite del Expediente de identificación, ingresemos tu CURP.",
  0
);

const node8 = new TreeNode(2, false, -3700, 1420, "Juego 1 (Proximamente)"); //juego 0

const node8_2 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Hay un problema con tu CURP, para arreglarlo tienes que ir a RENAPO, esto puede tardar hasta 70 días naturales en resolverse.",
  0
);

node8_2.quotes = [
  "“Nunca me había comentado del Expdiente y ahora resulta que lo necesito para hacer el trámite, sólo nos ponen trabas para no sacar el dinero.”",
];

const node8_2_1 = new TreeNode(0, false, -3700, 1420, "Muchas gracias.", 0);

const node8_2_2 = new TreeNode(
  0,
  false,
  -2177,
  1263,
  "Ya fui a RENAPO para resolver este tema, me comentaron que me dan resolución en 70 días, me urge el dinero y ya se detuvo todo por esto, pero bueno esperaré.",
  0
);

const node8_2_3 = new TreeNode(
  2,
  false,
  -2177,
  1263,
  "70 días después.",
  70 * 24
);

const node8_2_4 = new TreeNode(
  0,
  false,
  -2177,
  1263,
  "Listo, ya quedó resuelto lo de mi CURP, iré mañana a la sucursal de Profuturo para retomar el trámite.",
  0
);

const node8_2_4_1 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "Hola, ya había empezado a hacer el trámite, pero tuve un problema con mi CURP, ya quedó resuelto y vengo a hacer mi trámite de Expediente.",
  24
);

node8_2_4_1.dialogAnchor = { y: 2 };

const node8_2_4_1_1 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Perfecto, por favor pase con el asistente para ser atendido.",
  0
);

node8_2_4_1_1.dialogAnchor = { y: 2 };

const node8_2_4_2 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Buen día. Entiendo que viene a hacer su trámite de Expediente, ¿me puede compartir sus documentos por favor?",
  0
);

const node8_2_4_3 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Listo, ya quedó la solicitud para el trámite de Expediente. Este queda listo en aproximadamente en 5 días hábiles, por favor revisa tu correo, ya que cuando termine tendrás que venir nuevamente para hacer tu retiro por desempleo.",
  0
);

const node8_2_4_4 = new TreeNode(
  0,
  false,
  -3700,
  1420,
  "Perfecto, muchas gracias, estaré pendiente.",
  0
);

const node8_2_4_5 = new TreeNode(
  2,
  false,
  -3150,
  1358,
  "Después de 5 días.",
  5 * 24
);

const node8_2_4_6 = new TreeNode(
  0,
  false,
  -3150,
  1358,
  "Listo, ya me llegó la notificación de aceptación  de mi Expediente, ya tengo agendada mi cita para el día de mañana para el trámite de Retiro. Y tengo todos mis documentos listos.",
  0
);

const node8_3 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Listo, ya quedó la solicitud para el trámite de Expediente. Este queda listo en aproximadamente en 5 días hábiles, por favor revisa tu correo, ya que cuando termine tendrás que venir nuevamente para hacer tu retiro por desempleo.",
  0
);

const node8_3_0 = new TreeNode(
  0,
  false,
  -3700,
  1420,
  "Perfecto, muchas gracias, estaré pendiente.",
  0
);

node8_3.quotes = [
  "“La señorita me ayudó a capturar mi trámite muy rápido, que bueno que consideren los tiempos de sus clientes”",
];

const node8_3_1 = new TreeNode(2, false, -2177, 1263, "Pasan 5 días", 24 * 5);

const node8_3_1_0 = new TreeNode(
  0,
  false,
  -2177,
  1263,
  "Listo, ya me llegó la notificación de aceptación  de mi Expediente, ya tengo agendada mi cita para el día de mañana para el trámite de Retiro. Y tengo todos mis documentos listos.",
  0
);

/*

const node8_3_1_1 = new TreeNode(
  1,
  false,
  -2177,
  1263,
  "Ya me llegó la confirmación de que mi trámite de expedientees correcto, ya por fin podré hacer el trámite de retiro por desempleo.",
  0
);
const node8_3_1_2 = new TreeNode(
  2,
  false,
  -3700,
  1420,
  "Hubo problemas con las huellas, tardamos mucho en este paso",
  2
);

const node8_3_1_1_2 = new TreeNode(
  2,
  false,
  -3700,
  1420,
  "Juego 2 (Proximamente)"
); //juego 1

//node8_3_1_1_2.game = 6;
*/

const node8_4 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "Hola, recibí la confirmación de mi trámite de Expediente, y ahora quisiera hacer mi trámite de retiro por desempleo.",
  24
);

const node8_4_1 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Muy bien, ¿tienes todos los documentos?",
  0
);

node8_4_1.dialogAnchor = { y: 2 };

const node8_4_1_1 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "Sí, los traigo todos.",
  0
);

const node8_4_1_2 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Perfecto, por favor pase con el asistente para ser atendido.",
  0
);

node8_4_1_2.dialogAnchor = { y: 2 };

const node8_4_2 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Hola bienvenido a Profuturo, ¿tienes agendada una cita?",
  24
);

node8_4_2.dialogAnchor = { y: 2 };

const node8_4_4 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "No, pero me gustaría hacer un retiro por desempleo.",
  0
);

const node8_4_5 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Muy bien, ¿tienes todos los documentos?",
  0
);

node8_4_5.dialogAnchor = { y: 2 };

const node8_4_6 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "Sí, los traigo todos.",
  0
);

const node8_4_7 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Perfecto, por favor  tendrás  que esperar a que una de las asistentes se desocupe, ya que se le da prioridad a los clientes con cita.",
  0
);

node8_4_7.dialogAnchor = { y: 2 };

const node8_4_8 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "De acuerdo, muchas gracias.",
  0
);

const node8_4_9 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "Llevo 30 minutos, espero no se tarden más",
  0
);

const node8_4_10 = new TreeNode(
  2,
  false,
  -3215,
  1394,
  "Después de una hora.",
  1
);

const node8_4_11 = new TreeNode(2, false, -3215, 1394, "Después de 1 hora.", 1);

const node8_4_12 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Buen día. Entiendo que viene a un retiro por desempleo, ¿me puede compartir sus documentos por favor?",
  0
);

const node8_4_13 = new TreeNode(0, false, -3700, 1420, "Aquí están.", 0);

const node8_4_14 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Llenaremos la solicitud en línea.",
  0
);

const node8_5 = new TreeNode(2, false, -3700, 1420, "Juego 3 (Proximamente)"); //juego 2

const node8_5_1 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Al parecer hay intermitencias en los sistemas y no podremos continuar con el trámite.",
  0
);

const node8_5_1_1 = new TreeNode(
  0,
  false,
  -3700,
  1420,
  "¿Crees que se estabilicen pronto?",
  0
);

const node8_5_1_2 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "No lo sabemos, pero si estás de acuerdo, le agendamos un espacio mañana para continuar con el trámite.",
  0
);

const node8_5_1_3 = new TreeNode(0, false, -3700, 1420, "Está bien.", 0);

node8_5_1.quotes = [
  "“Se les olvida que es dinero del ahorrador, su aplicación para celular NUNCA funcionó, su personal siempre le echa la culpa al IMSS, y mientras uno apenas vive, el dinero no es para la parranda, ¡es para SOBREVIVIR!”",
];

const node8_5_2 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "No hay sistema del IMSS, tendré que regresar. El tramite está tardando mucho, ¡ya necesito el dinero!",
  2
);

node8_5_2.quotes = [
  "“Me rechazaron el trámite por un diagnóstico que no entendí, la persona de sucursal fue muy amable y me explicó qué es lo que debía de hacer”",
];

const node8_5_3 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "No existe consulta para la certificación parcial, tendré que regresar. El tramite está tardando mucho, ¡ya necesito el dinero!",
  2
);

node8_5_3.quotes = [
  "“Me rechazaron el trámite por un diagnóstico que no entendí, la persona de sucursal fue muy amable y me explicó qué es lo que debía de hacer”",
];

const node8_5_4 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Todos los sistemas están estables, llenemos la solicitud.",
  2
);

node8_5_4.quotes = [
  "“Hace unos años había hecho el trámite de retiro y fue muy tardado, que bueno que mejoraron su atención y sus procesos, hacen que sea más fácil la situación que uno vive”",
];

const node8_6 = new TreeNode(2, false, -3085, 1324, "Al día siguiente", 24);

const node8_6_1 = new TreeNode(
  0,
  false,
  -3215,
  1394,
  "Hola, ya había empezado a hacer el trámite de retiro por desempleo pero hubo intermitencia en el sistema, vengo a continuar con el trámite.",
  24
);

node8_6_1.dialogAnchor = { y: 2 };

const node8_6_2 = new TreeNode(
  1,
  false,
  -3215,
  1394,
  "Perfecto, por favor pase con el asistente para ser atendido.",
  0
);

node8_6_2.dialogAnchor = { y: 2 };

const node8_7 = new TreeNode(2, false, -3700, 1420, "Juego 4 (Proximamente)"); //juego 3

const node8_7_1 = new TreeNode(
  2,
  false,
  -3700,
  1420,
  "Mandamos la solicitud a validar, pero veo que hubo un problema.",
  2
);

node8_7_1.quotes = [
  "“No me dejaron sacar lo que quería, me dieron un tope de monto, pero al final ese dinero es mío...”",
];

const node8_7_1_1 = new TreeNode(
  2,
  false,
  -3700,
  1420,
  "Dicen que sigo activo ante el IMSS, tienen que pasar 46 días para que me den de baja. ¡Necesito dinero!",
  2
);

const node8_7_1_2 = new TreeNode(
  2,
  false,
  -3700,
  1420,
  "46 días después.",
  24 * 46
);

const node8_7_2 = new TreeNode(
  2,
  false,
  -3700,
  1420,
  "Mandamos la solicitud a validar, pero veo que hubo un problema.",
  2
);

node8_7_2.quotes = [
  "“No me dejaron sacar lo que quería, me dieron un tope de monto, pero al final ese dinero es mío...”",
];

const node8_7_2_1 = new TreeNode(
  0,
  false,
  -3700,
  1420,
  "Hice otro retiro por desempleo en los últimos cinco años, no me paso los requisitos y no me pueden liberar dinero, ¿qué hago? ¡Necesito dinero!",
  2
);

const node8_7_3 = new TreeNode(
  2,
  false,
  -3700,
  1420,
  "Mandamos la solicitud a validar, pero veo que hubo un problema.",
  0
);

node8_7_3.quotes = [
  "“No me dejaron sacar lo que quería, me dieron un tope de monto, pero al final ese dinero es mío...”",
];

const node8_7_3_1 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Al parecer estás en demanda con tu patrón, por lo que hasta que no termine, no podremos hacer el trámite.",
  0
);

const node8_7_3_2 = new TreeNode(
  0,
  false,
  -3700,
  1420,
  "¿No hay nada más que se pueda hacer?",
  0
);

const node8_7_3_3 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Nada hasta que termine la demanda.",
  0
);

const node8_7_3_4 = new TreeNode(
  0,
  false,
  -3700,
  1420,
  "Ok, muchas gracias.",
  0
);

const node8_7_3_5 = new TreeNode(
  0,
  false,
  -3560,
  1694,
  "No puedo creer que no pueda hacer el retiro por eso, es mi cuenta de Afore debería de poder. Pero bueno, veré que hago para mantenerme.",
  0
);

const node8_7_4 = new TreeNode(
  2,
  false,
  -3700,
  1420,
  "Mandamos la solicitud a validar, pero veo que hubo un problema.",
  2
);

node8_7_4.quotes = [
  "“No me dejaron sacar lo que quería, me dieron un tope de monto, pero al final ese dinero es mío...”",
];

const node8_7_4_1 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "Al parecer hubo una omisión en la solicitud, las firmas no coinciden, tendremos que llenarla y mandarla nuevamente.",
  2
);

const node8_7_5 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "El diagnóstico fue aceptado",
  2
);

node8_7_5.quotes = [
  "“Me explicaron las consecuencias de hacer el retiro, está bien que nos digan para estar informados y poder hacer algo después”",
];

const node8_7_5_1 = new TreeNode(
  1,
  false,
  -3700,
  1420,
  "No hubo ningún problema con la solicitud. Ahora tenemos que esperar 5 días hábiles, por favor mantente al tanto de tu correo, ahí te avisaremos cuando el depósito se haya realizado.",
  0
);

const node8_7_5_2 = new TreeNode(
  0,
  false,
  -3700,
  1420,
  "Muchas gracias, estaré pendiente.",
  0
);

node8_7_5_2.dialogAnchor = { y: 2 };

node8_7_5_2.quotes = [
  "“Me debieron depositar a inicios del mes y sigo esperando el deposito, por eso es apoyo de desempleo porque no tengo un ingreso y requiero de dinero para saldar deudas y gasto corriente”",
  "“Demasiado complejos los requisitos y además el trámite deberia ser único y no en abonos”",
];

const node8_7_6 = new TreeNode(
  0,
  false,
  -3560,
  1694,
  "Por fin, después de mil trámites pude hacer mi retiro por desempleo, estaré al pendiente de mi correo.",
  24 * 5
);

const node8_7_6_1 = new TreeNode(
  2,
  false,
  -3560,
  1694,
  "Después de 3 días",
  3 * 24
);

const node8_7_6_2 = new TreeNode(
  0,
  false,
  -3560,
  1694,
  "Me acaba de llegar la notificación de que ya me depositaron, ¡Por fin podré estar tranquilo un tiempo!",
  0
);

node8_7_6.quotes = [
  "“Esperaba el dinero en 5 días y me lo depositaron en 3, sentí que Profuturo llegó como ángel”",
];

const node8_8 = new TreeNode(
  2,
  false,
  -3560,
  1694,
  "No pudo hacer el retiro",
  2
);

const node9a = new TreeNode(2, false, -3560, 1694);
const node9b = new TreeNode(2, false, -3560, 1694);

//////
/**
  woman
  -3215,
  1394,

 desk
  -3700,
  1420,
 */

//start
node1.descendants = [
  {
    node: node1_2,
    hint: "Le preguntaré a amigos y familiares",
  },
  {
    node: node1_3,
    hint: "Buscaré en internet",
  },
];
node1_2.descendants = [
  {
    node: node4,
    hint: "Lo más seguro es que sí sea Profuturo, buscaré su contacto",
  },
  { node: node2_2, hint: "Buscaré en internet" },
];

node1_3.descendants = [
  {
    node: node4,
    hint: "Lo más seguro es que sí sea Profuturo, buscaré su contacto",
  },
  { node: node2_2, hint: "Buscaré en internet" },
];

/*
node2.descendants = [
  {
    node: node4,
    hint: "Profuturo es mi Afore",
  },
  {
    node: node2_2,
    hint: "Preguntaré en Recursos Humanos de mi empresa",
  },
  {
    node: node3,
    hint: "Revisaré en internet cómo saber en qué Afore estoy",
  },
];
*/

node2_2.descendants = [
  {
    node: node4,
    hint: "Continuar",
  },
];

/*
node3.descendants = [
  {
    node: node4,
    hint: "Continuar",
  },
];
*/
node4.descendants = [
  {
    node: node5_1,
    hint: "Marcaré para que me den toda la información",
  },
  {
    node: node4_1,
    hint: "Visitaré la sucursal más cerca de mi casa para que me apoyen con el trámite",
  },
];
//revisar
node4_1.descendants = [
  {
    node: random([node6_2_1, node6_2]),
    hint: "Me conviene agendar cita para no esperar en la sucursal",
  },
  {
    node: node7_2,
    hint: "Me urge el dinero, prefiero ir mañana aunque espere mucho tiempo",
  },
];

//eliminar?
node4_1_1.descendants = [
  {
    node: random([node6_2, node6_2_1, node6_3]),
    hint: "Continuar",
  },
];

//eliminar?
node4_1_2.descendants = [
  {
    node: random([node6_2, node6_2_1, node6_3]),
    hint: "Continuar",
  },
];

/*
node5.descendants = [
  {
    node: node5_2,
    hint: "Sí, soy cliente de profuturo",
  },
  {
    node: node5_3,
    hint: "No, no estoy registrado",
  },
];

node5_2.descendants = [
  {
    node: node5_2_1,
    hint: "Sí, tengo el Expediente de Id",
  },
  {
    node: node5_2_2,
    hint: "No tengo el expediente de Id",
  },
];
*/

node5_1.descendants = [{ node: node5_1_1, hint: "Continuar" }];

node5_1_1.descendants = [{ node: node5_1_2, hint: "Continuar" }];

node5_1_2.descendants = [{ node: node5_1_3, hint: "Continuar" }];

node5_1_3.descendants = [
  {
    node: random([node5_2_1_4, node5_2_2, node5_3_4]),
    hint: "Proporcionar CURP",
  },
];

/*

node5_2_1.descendants = [
  {
    node: node5_2_1_1,
    hint: "Continuar",
  },
];

node5_2_1_1.descendants = [
  {
    node: node5_2_1_2,
    hint: "Continuar",
  },
];

node5_2_1_2.descendants = [
  {
    node: node5_2_1_3,
    hint: "Continuar",
  },
];

node5_2_1_3.descendants = [
  {
    node: node5_2_1_4,
    hint: "Continuar",
  },
];

*/

node5_2_1_4.descendants = [
  {
    node: node5_2_1_5,
    hint: "Continuar",
  },
];

node5_2_1_5.descendants = [
  {
    node: node5_2_1_6,
    hint: "Continuar",
  },
];

node5_2_1_6.descendants = [
  {
    node: node8_4,
    hint: "Prefiero llegar con cita, muchas gracias.",
  },
  {
    node: node8_4_2,
    hint: "Me urge el dinero, pasaré mañana a ver si hay algún espacio, muchas gracias.",
  },
];

node5_2_2.descendants = [
  {
    node: node7,
    hint: "Me conviene agendar una cita para no esperar en la sucursal",
  },
  {
    node: node7_2,
    hint: "Me urge el dinero, prefiero ir mañana aunque espere mucho tiempo",
  },
];

node5_3.descendants = [
  {
    node: node5_3_1,
    hint: "Continuar",
  },
];

node5_3_1.descendants = [
  {
    node: node5_3_2,
    hint: "Continuar",
  },
];

node5_3_2.descendants = [
  {
    node: node5_3_3,
    hint: "Continuar",
  },
];

node5_3_3.descendants = [
  {
    node: node5_3_4,
    hint: "Proporcionar CURP",
  },
];

node5_3_4.descendants = [
  {
    node: node5_3_5,
    hint: "Continuar",
  },
];

node5_3_5.descendants = [
  {
    node: node5_3_6,
    hint: "Continuar",
  },
];

node5_3_6.descendants = [
  {
    node: node5_3_7,
    hint: "Continuar",
  },
];

node5_3_7.descendants = [
  {
    node: node5_3_9,
    hint: "Continuar",
  },
];

node5_3_9.descendants = [
  {
    node: node7,
    hint: "Me conviene agendar una cita para no esperar en la sucursal",
  },
  {
    node: node7_2,
    hint: "Me urge el dinero, prefiero ir mañana aunque espere mucho tiempo",
  },
];

/*
node6.descendants = [
  {
    node: node6_2,
    hint: "Sí, soy cliente de profuturo",
  },
  {
    node: node6_3,
    hint: "No, no estoy registrado",
  },
];
*/

node6_2.descendants = [
  {
    node: node6_2_0_1,
    hint: "Continuar",
  },
];

node6_2.descendants = [
  {
    node: node6_2a_1,
    hint: "Continuar",
  },
];

node6_2a_1.descendants = [
  {
    node: node6_2a_2,
    hint: "Continuar",
  },
];

node6_2a_2.descendants = [
  {
    node: node6_2_0_1,
    hint: "Continuar",
  },
];

node6_2_0_1.descendants = [
  {
    node: node6_2_0_2,
    hint: "Continuar",
  },
];

node6_2_0_2.descendants = [
  {
    node: node8,
    hint: "Continuar",
  },
];

node6_2_1.descendants = [
  {
    node: node6_2_1a_1,
    hint: "Continuar",
  },
];

node6_2_1a_1.descendants = [
  {
    node: node8_4_12,
    hint: "Continuar",
  },
];

node6_2_1a_2.descendants = [
  {
    node: node6_2_1_1,
    hint: "Continuar",
  },
];

node6_2_1_1.descendants = [
  {
    node: node6_2_1_2,
    hint: "Continuar",
  },
];

node6_2_1_2.descendants = [
  {
    node: node8_4,
    hint: "Compartir",
  },
];

node6_3.descendants = [
  {
    node: node6_3_1,
    hint: "Continuar",
  },
];

node6_3_1.descendants = [
  {
    node: node6_3_2,
    hint: "Entregar.",
  },
];

node6_3_2.descendants = [
  {
    node: node6_3_3,
    hint: "Continuar",
  },
];

node6_3_3.descendants = [
  {
    node: node6_3_4,
    hint: "Continuar",
  },
];

node6_3_4.descendants = [
  {
    node: node6_3_5,
    hint: "Continuar",
  },
];

node6_3_5.descendants = [
  {
    node: node6_3_6,
    hint: "Continuar",
  },
];

node6_3_6.descendants = [
  {
    node: node6_3_7,
    hint: "Continuar",
  },
];

node6_3_7.descendants = [
  {
    node: node6_3_8,
    hint: "Continuar",
  },
];
node6_3_8.descendants = [
  {
    node: node6_3_9,
    hint: "Continuar",
  },
];

node6_3_9.descendants = [
  {
    node: node7,
    hint: "Me conviene agendar una cita para no esperar en la sucursal",
  },
  {
    node: random([node7_2, node8_4_2]),
    hint: "Me urge el dinero, prefiero ir mañana aunque espere mucho tiempo",
  },
];

node7.descendants = [
  {
    node: node7_1,
    hint: "Continuar",
  },
];

node7_1.descendants = [
  {
    node: node7_1_1,
    hint: "Continuar",
  },
];

node7_1_1.descendants = [
  {
    node: node7_1_2,
    hint: "Continuar",
  },
];

node7_1_2.descendants = [
  {
    node: node7_1_3,
    hint: "Continuar",
  },
];

node7_1_3.descendants = [
  {
    node: node7_1_4,
    hint: "Continuar",
  },
];

node7_1_4.descendants = [
  {
    node: node7_1_5,
    hint: "Continuar",
  },
];

node7_1_5.descendants = [
  {
    node: node7_1_6,
    hint: "Continuar",
  },
];

node7_1_6.descendants = [
  {
    node: node7_1_7,
    hint: "Continuar",
  },
];

node7_1_7.descendants = [
  {
    node: node8,
    hint: "Continuar",
  },
];

node7_2.descendants = [
  {
    node: node7_2_2,
    hint: "Continuar",
  },
];

node7_2_2.descendants = [
  {
    node: node7_2_3,
    hint: "Continuar",
  },
];

node7_2_3.descendants = [
  {
    node: node7_2_4,
    hint: "Continuar",
  },
];

node7_2_4.descendants = [
  {
    node: node7_2_5,
    hint: "Continuar",
  },
];

node7_2_5.descendants = [
  {
    node: node7_2_6,
    hint: "Continuar",
  },
];

node7_2_6.descendants = [
  {
    node: node7_2_6_1,
    hint: "Continuar",
  },
];

node7_2_6_1.descendants = [
  {
    node: node7_2_7,
    hint: "Continuar",
  },
];

node7_2_7.descendants = [
  {
    node: node7_2_8,
    hint: "Continuar",
  },
];

node7_2_8.descendants = [
  {
    node: node7_2_9,
    hint: "Continuar",
  },
];

node7_2_9.descendants = [
  {
    node: node8,
    hint: "Continuar",
  },
];

node7_3.descendants = [
  {
    node: node8,
    hint: "Continuar",
  },
];

node8.descendants = [
  {
    node: node8_2,
    hint: "Resultado 1",
  },
  {
    node: node8_3,
    hint: "Resultado 2",
  },
];

node8.game = 5;

node8_2.descendants = [
  {
    node: node8_2_1,
    hint: "Continuar",
  },
];

node8_2_1.descendants = [
  {
    node: node8_2_2,
    hint: "Continuar",
  },
];

node8_2_2.descendants = [
  {
    node: node8_2_3,
    hint: "Continuar",
  },
];

node8_2_3.descendants = [
  {
    node: node8_2_4,
    hint: "Continuar",
  },
];

node8_2_4.descendants = [
  {
    node: node8_2_4_1,
    hint: "Continuar",
  },
];

node8_2_4_1.descendants = [
  {
    node: node8_2_4_1_1,
    hint: "Continuar",
  },
];

node8_2_4_1_1.descendants = [
  {
    node: node8_2_4_2,
    hint: "Continuar",
  },
];

node8_2_4_2.descendants = [
  {
    node: node8_2_4_3,
    hint: "Continuar",
  },
];

node8_2_4_3.descendants = [
  {
    node: node8_2_4_4,
    hint: "Continuar",
  },
];

node8_2_4_4.descendants = [
  {
    node: node8_2_4_5,
    hint: "Continuar",
  },
];

node8_2_4_5.descendants = [
  {
    node: node8_2_4_6,
    hint: "Continuar",
  },
];

node8_2_4_6.descendants = [
  {
    node: node8_4,
    hint: "Continuar",
  },
];

node8_3.descendants = [
  {
    node: node8_3_0,
    hint: "Continuar",
  },
];

node8_3_0.descendants = [
  {
    node: node8_3_1,
    hint: "Continuar",
  },
];

node8_3_1.descendants = [
  {
    node: node8_3_1_0,
    hint: "Continuar",
  },
];

node8_3_1_0.descendants = [
  {
    node: node8_4,
    hint: "Continuar",
  },
];

/*
node8_3_1_1_2.descendants = [
  {
    node: node8_3_1_2,
    hint: "Resultado",
  },
];

node8_3_1_1_2.game = 3;

node8_3_1_2.descendants = [
  {
    node: node8_4,
    hint: "Continuar",
  },
];
*/

node8_4.descendants = [
  {
    node: node8_4_1,
    hint: "Continuar",
  },
];

node8_4_1.descendants = [
  {
    node: node8_4_1_1,
    hint: "Continuar",
  },
];

node8_4_1_1.descendants = [
  {
    node: node8_4_1_2,
    hint: "Continuar",
  },
];

node8_4_1_2.descendants = [
  {
    node: node8_4_12,
    hint: "Continuar",
  },
];

node8_4_2.descendants = [
  {
    node: node8_4_4,
    hint: "Continuar",
  },
];

node8_4_4.descendants = [
  {
    node: node8_4_5,
    hint: "Continuar",
  },
];

node8_4_5.descendants = [
  {
    node: node8_4_6,
    hint: "Continuar",
  },
];

node8_4_6.descendants = [
  {
    node: node8_4_7,
    hint: "Continuar",
  },
];

node8_4_7.descendants = [
  {
    node: node8_4_8,
    hint: "Continuar",
  },
];

node8_4_8.descendants = [
  {
    node: node8_4_9,
    hint: "Continuar",
  },
];

node8_4_9.descendants = [
  {
    node: random([node8_4_10, node8_4_11]),
    hint: "Continuar",
  },
];

node8_4_10.descendants = [
  {
    node: node8_4_12,
    hint: "Continuar",
  },
];

node8_4_11.descendants = [
  {
    node: node8_4_12,
    hint: "Continuar",
  },
];

node8_4_12.descendants = [
  {
    node: node8_4_13,
    hint: "Continuar",
  },
];

node8_4_13.descendants = [
  {
    node: node8_4_14,
    hint: "Compartir",
  },
];

node8_4_14.descendants = [
  {
    node: node8_5,
    hint: "Continuar",
  },
];

node8_5.descendants = [
  {
    node: node8_5_1,
    hint: "Resultado 1",
  },
  {
    node: node8_5_2,
    hint: "Resultado 2",
  },
  {
    node: node8_5_3,
    hint: "Resultado 3",
  },
  {
    node: node8_5_4,
    hint: "Resultado 4",
  },
];

node8_5.game = 7;

node8_5_1.descendants = [
  {
    node: node8_5_1_1,
    hint: "Continuar",
  },
];

node8_5_1_1.descendants = [
  {
    node: node8_5_1_2,
    hint: "Continuar",
  },
];

node8_5_1_2.descendants = [
  {
    node: node8_5_1_3,
    hint: "Continuar",
  },
];

node8_5_1_3.descendants = [
  {
    node: node8_6,
    hint: "Continuar",
  },
];

node8_5_2.descendants = [
  {
    node: node8_6,
    hint: "Continuar",
  },
];

node8_5_3.descendants = [
  {
    node: node8_6,
    hint: "Continuar",
  },
];

node8_5_4.descendants = [
  {
    node: node8_7,
    hint: "Continuar",
  },
];

node8_6.descendants = [
  {
    node: node8_6_1,
    hint: "Continuar",
  },
];

node8_6_1.descendants = [
  {
    node: node8_6_2,
    hint: "Continuar",
  },
];

node8_6_2.descendants = [
  {
    node: node8_5_4,
    hint: "Continuar",
  },
];

node8_7.descendants = [
  {
    node: node8_7_1,
    hint: "Resultado 1",
  },
  {
    node: node8_7_2,
    hint: "Resultado 2",
  },
  {
    node: node8_7_3,
    hint: "Resultado 3",
  },
  {
    node: node8_7_4,
    hint: "Resultado 4",
  },
  {
    node: node8_7_5,
    hint: "Resultado 5",
  },
];

node8_7.game = 6;

node8_7_1.descendants = [
  {
    node: node8_7_1_1,
    hint: "¿Qué pasó?",
  },
];

node8_7_1_1.descendants = [
  {
    node: node8_7_1_2,
    hint: "Continuar",
  },
];

node8_7_1_2.descendants = [
  {
    node: node8_5_4,
    hint: "Continuar",
  },
];

node8_7_2.descendants = [
  {
    node: node8_7_2_1,
    hint: "¿Qué pasó?",
  },
];

node8_7_2_1.descendants = [
  {
    node: node9b,
    hint: "Continuar",
  },
];

node8_7_3.descendants = [
  {
    node: node8_7_3_1,
    hint: "¿Qué pasó?",
  },
];

node8_7_3_1.descendants = [
  {
    node: node8_7_3_2,
    hint: "Continuar",
  },
];

node8_7_3_2.descendants = [
  {
    node: node8_7_3_3,
    hint: "Continuar",
  },
];

node8_7_3_3.descendants = [
  {
    node: node8_7_3_4,
    hint: "Continuar",
  },
];

node8_7_3_4.descendants = [
  {
    node: node8_7_3_5,
    hint: "Continuar",
  },
];

node8_7_3_5.descendants = [
  {
    node: node9b,
    hint: "Continuar",
  },
];

node8_7_4.descendants = [
  {
    node: node8_7_4_1,
    hint: "¿Qué pasó?",
  },
];

node8_7_4_1.descendants = [
  {
    node: node8_7,
    hint: "Continuar",
  },
];

node8_7_5.descendants = [
  {
    node: node8_7_5_1,
    hint: "¿Qué sigue?",
  },
];

node8_7_5_1.descendants = [
  {
    node: node8_7_5_2,
    hint: "Continuar",
  },
];

node8_7_5_2.descendants = [
  {
    node: node8_7_6,
    hint: "Continuar",
  },
];

node8_7_6.descendants = [
  {
    node: node8_7_6_1,
    hint: "Continuar",
  },
];

node8_7_6_1.descendants = [
  {
    node: node8_7_6_2,
    hint: "Continuar",
  },
];
//ya me depositaron
node8_7_6_2.descendants = [
  {
    node: node9a,
    hint: "Continuar",
  },
];
//no pudo hacer el retiro
node8_8.descendants = [
  {
    node: node9b,
    hint: "Continuar",
  },
];

node9a.isLast = true;
node9a.ending = 0;

node9b.isLast = true;
node9b.ending = 1;

export default node1;
