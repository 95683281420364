import { createSlice } from "@reduxjs/toolkit";

export const withdrawalsTimeSlice = createSlice({
  name: "withdrawalsTime",
  initialState: {
    timeCount: 0,
  },
  reducers: {
    addTime: (state, action) => {
      state.timeCount += action.payload;
    },
    resetTime: (state) => {
      state.timeCount = 0;
    },
  },
});

export const { addTime, resetTime } = withdrawalsTimeSlice.actions;

export default withdrawalsTimeSlice.reducer;
