import { createSlice } from "@reduxjs/toolkit";
import audio from "../../assets/music/background_music.mp3";

export const audioSlice = createSlice({
  name: "audio",
  initialState: {
    currentAudio: audio,
    interrupted: false,
    finished: false,
    loop: true,
  },
  reducers: {
    setAudio: (state, action) => {
      state.currentAudio = action.payload;
      state.loop = false;
    },
    resetAudio: (state) => {
      state.currentAudio = audio;
      state.finished = false;
      state.loop = true;
    },
    interruptAudio: (state, action) => {
      state.interrupted = action.payload;
    },
    setFinished: (state, action) => {
      state.finished = action.payload;
    },
    setLoop: (state, action) => {
      state.loop = action.payload;
    },
  },
});

export const { setAudio, resetAudio, interruptAudio, setFinished, setLoop } =
  audioSlice.actions;

export default audioSlice.reducer;
