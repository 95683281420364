import React, { useState, useEffect, useCallback } from "react";
import { Container, Sprite, Graphics } from "@inlet/react-pixi/animated";
import { Spring } from "react-spring";

import hand130 from "../../../assets/games/weeksGame/130.png";
import hand80 from "../../../assets/games/weeksGame/80.png";
import hand110 from "../../../assets/games/weeksGame/110.png";
import hand180 from "../../../assets/games/weeksGame/180.png";
import ok from "../../../assets/games/contributionGame/ok.png";

const Hand = ({
  initialPos = { x: 100, y: 100 },
  currentPos = { x: 100, y: 100 },
  isVisible = false,
  amount = 100,
  onClick = () => null,
}) => {
  const spring = { mass: 8, tension: 1000, friction: 100 };
  const [fingerprintVisible, setFingerprintVisible] = useState(false);
  const images = { 130: hand130, 80: hand80, 110: hand110, 180: hand180 };

  const onPressHandler = () => {
    setFingerprintVisible(true);
    if (!fingerprintVisible) onClick();
  };

  const container = useCallback((graphics) => {
    graphics.clear();
    graphics.beginFill(0xffffff, 0.0000001);
    graphics.drawRoundedRect(-50, -20, 190, 135, 40);
    graphics.endFill();
  }, []);

  if (!isVisible) return null;

  return (
    <Spring
      native
      from={{ ...initialPos }}
      to={{ ...currentPos }}
      config={spring}
    >
      {(props) => (
        <Container {...props}>
          <Sprite image={images[amount]} height={500} width={900} />
          <Container
            y={amount !== 180 ? 40 : 25}
            x={amount !== 180 ? 515 : 250}
            interactive={true}
            cursor={"pointer"}
            pointerdown={onPressHandler}
            pointerup={onPressHandler}
          >
            <Graphics
              visible={!fingerprintVisible}
              draw={container}
              zIndex={1}
            />
            <Sprite
              visible={fingerprintVisible}
              image={ok}
              height={100}
              width={100}
              zIndex={2}
            />
          </Container>
        </Container>
      )}
    </Spring>
  );
};

export default Hand;
