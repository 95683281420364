import React, { memo, useEffect, useState } from "react";

const Loading = memo(({ onStart }) => {
  const [count, setCount] = useState(0);
  const [tips, setTips] = useState();
  const [currentTip, setCurrentTip] = useState();

  useEffect(() => {
    setTips(
      [
        { text: "Para navegar en el mapa utiliza las flechas.", time: 3000 },
        {
          text: "Para ingresar a cada etapa debes hacer clic en el botón naranja que contiene la descripción de la situación o trámite que conocerás.",
          time: 7000,
        },
        {
          text: "En la esquina inferior izquierda se encuentra el botón de sonido; al hacer clic lo activas y desactivas.",
          time: 5000,
        },
        {
          text: "Una vez que tomas una desición no podrás regresar para cambiarla y deberás completar el flujo correspondiente.",
          time: 4000,
        },
        {
          text: "En la esquina inferior derecha verás un reloj que simula el tiempo que acumulan nuestros clientes para completar el trámite. Tu objetivo es acumular el menor tiempo posible.",
          time: 7000,
        },
        {
          text: "Al finalizar cada etapa te pediremos recomendaciones para mejorar la experiencia de nuestros clientes, por lo que te recomendamos tomar notas durante tu navegación y considerar ❝La voz de nuestros clientes❞ que aparecerá a lo largo del juego a los costados.",
          time: 10000,
        },
        {
          text: "¡Disfruta y ayudanos a mejorar la experiencia de nuestros clientes!",
          time: 3000,
        },
      ].sort((a, b) => 0.5 - Math.random())
    );
  }, []);

  useEffect(() => {
    if (tips) {
      if (!currentTip) setCurrentTip(tips[0].text);
      setTimeout(() => {
        const next = count + 1 < tips.length ? count + 1 : 0;
        setCurrentTip(tips[next].text);
        setCount(next);
      }, tips[count].time);
    }
  }, [tips, count]);

  useEffect(() => {
    if (currentTip) {
      setTimeout(() => {
        onStart();
      }, 100);
    }
  }, [currentTip]);

  return <div className="loading">{false && currentTip}</div>;
});

export default Loading;
